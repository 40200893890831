import { useState, useEffect } from 'react';

type InstancePermissionsProps = {
    accessToken: string;
    email: string;
};

export type InstancePermissionItem = {
    "InstanceId": string;
    "InstanceName": string;
    "InstanceNickname": string;
    "NavigationUrl"?: string;
    "Region"?: string;
    "GroupName": string;
    "GroupId": string;
    "ProductId": string;
    "ProductName": string;
    "ProductType": string;
    "AllowCustomerDefinedRoles": boolean;
    "HasUsageDashboard": boolean;
    "IsService": boolean;
    "IsHomeOrganization": boolean;
    "OrganizationName": string;
    "OrganizationId": string;
};

export const useInstancePermissions = ({ accessToken, email }: InstancePermissionsProps) => {
    const [instancePermissions, setInstancePermissions] = useState<InstancePermissionItem[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_IDX_SERVICE_URL}/api/users/${email}/instancepermissions?size=10000`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });
                const data = await response.json();
                setInstancePermissions(data?.items || []);
            } catch (err) {
                console.error('Failed to fetch instance permissions', err);
                setError('Failed to fetch instance permissions');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [accessToken, email]);

    return { instancePermissions, loading, error };
};
