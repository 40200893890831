import styles from './LoadingIndicator.module.scss';

export const LoadingIndicator = ({ size = 120 }: { size?: number }) => {
    const width = "250";

    return (
        <div className="flex flex-column justify-content-center align-items-center" style={{ height: "calc(100vh - 48px)" }}>
            <img src={"/logo.png"} alt="Optimizely" width={width} height="70"  />
            <div style={{ width: `${width}px` }}>
                <ul className={styles['loading-indicator']}>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </div>
        </div>
    )
};

LoadingIndicator.displayName = 'LoadingIndicator';