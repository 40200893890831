import { Grid, GridCell, Typography } from '@optimizely/axiom';
import React from 'react';

export const InvitationError = () => {

    return (
        <Grid className="flex flex-column align-items-center">
            <GridCell large={12} medium={8} small={4}>
                <img src="/invitation-expired.png" alt="Error" width={233} height={150} />
            </GridCell>
            <GridCell large={12} medium={8} small={4}>
                <Typography type="header4">Something went wrong</Typography>
            </GridCell>
            <GridCell large={12} medium={8} small={4}>
                <Typography type="body" className="push--top">
                    Please try again later or contact your administrator for assistance
                </Typography>
            </GridCell>

        </Grid>
    );
};

InvitationError.displayName = 'InvitationError';