import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Returns search params and allows getting a parameter by name.
 * Usage:
 * const query = useQuery();
 * const id = query.get('id');
 */
export function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}