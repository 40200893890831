import React from "react";

export const ArrowDown = ({onClick}: { onClick?: () => any }) => {
    return (
        <svg onClick={onClick} width="20" height="16" viewBox="0 0 20 16" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.3125 6.37498L10.3437 10.875C10.1562 11.0625 9.87498 11.0625 9.68748 10.875L4.65623 6.37498C4.43748 6.18748 4.43748 5.87498 4.62498 5.68748C4.81248 5.46873 5.12498 5.46873 5.31248 5.65623L9.99998 9.84373L14.6562 5.65623C14.8437 5.46873 15.1562 5.46873 15.3437 5.68748C15.5312 5.87498 15.5312 6.18748 15.3125 6.37498Z"
                fill="#080736"/>
        </svg>
    );
}

ArrowDown.displayName = "ArrowDown";