import { SigninWithRedirectOptions } from "@okta/okta-auth-js";
import { URLSearchParams } from "url";

export default class OktaOptionsBuilder {
  buildOktaOptions(params: URLSearchParams): SigninWithRedirectOptions {
    const idpId = params.get("idpId");
    const brand = params.get("brand");

    if (idpId) {
      return { idp: idpId };
    } else if (brand) {
      return { extraParams: { brand: brand } };
    } else {
      return {};
    }
  }
}
