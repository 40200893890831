import { EmptyDashboard as AxiomEmptyDashboard, Link } from "@optimizely/axiom";
import styles from "./EmptyDashboard.module.scss";

type EmptyDashboardProps = {
    name: string;
}

export const EmptyDashboard = ({ name }: EmptyDashboardProps) => {
    return (
        <AxiomEmptyDashboard
            className={styles["empty-dashboard"]}
            headline={name ? `Welcome ${name}!` : "Welcome!"}
            description={
                <div className={styles["empty-dashboard__description"]}>
                    It seems you don't have any products yet. They'll appear here once you have access.
                    <div>
                        <Link isDisabled>Contact your admin</Link> for further assistance.
                    </div>
                </div >
            }
            imagePath="/empty-dashboard-icon.png"
            imageHeight="auto"
            imageWidth="auto"
        />
    );
};

EmptyDashboard.displayName = "EmptyDashboard";
