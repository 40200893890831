export const getInstancesByProducts = ({ instances }: { instances: any[] }) => {
    return instances?.reduce((acc: any = {}, curr: any) => {
        const { ProductName } = curr;
        if (!acc[ProductName]) {
            acc[ProductName] = [curr];
        } else {
            acc[ProductName].push(curr);
        }
        return acc;
    }, {});
}


export const getHomeOrganizationFromInstances = ({ instances = [] }: { instances: any[] }) => {
    return instances?.find(i => i.IsHomeOrganization)?.OrganizationId;
}

export const getInstancesForProduct = ({ instances, organizationId, productName }: { instances: any [], organizationId: string, productName: string }) => {
    const product = decodeURI(productName);
    return instances?.filter(i => i.ProductName?.toLowerCase() === product?.toLowerCase() && i.OrganizationId === organizationId);

}