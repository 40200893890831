export const config = {
    APP_ENVIRONMENT: process.env.REACT_APP_ENV || "local",
    CLIENT_ID: process.env.REACT_APP_OKTA_CLIENT_ID, // User Management App
    DATADOG_APP_ID: process.env.REACT_APP_DATADOG_APP_ID,
    DATADOG_CLIENT_TOKEN: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    IDX_SERVICE_URL: process.env.REACT_APP_IDX_SERVICE_URL,
    OKTA_ISSUER: process.env.REACT_APP_OKTA_ISSUER, // https://optimizelyapp.oktapreview.com/oauth2/default
    OPTIMIZELY_SDK_KEY: process.env.REACT_APP_OPTIMIZELY_SDK_KEY,
    PKCE: Boolean(process.env.REACT_APP_OIDC_PKCE),
    REDIRECT_URI: window.location.origin + process.env.REACT_APP_OIDC_CALLBACK_PATH,
    RESPONSE_TYPE: "code",
    SCOPE: ["openid", "profile", "email", "offline_access", "idx_instancepermissions"],
};