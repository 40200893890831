import { Grid, Typography } from "@optimizely/axiom";
import { ProductGrid } from "../ProductGrid/ProductGrid";
import styles from "./ProductAccessDashboard.module.scss"

type DashboardLayoutProps = {
    name: string;
    email: string;
    products: any;
}

export const ProductAccessDashboard = ({ name, email, products }: DashboardLayoutProps) => {
    const welcomeMessage = Boolean(name) ? `Welcome back, ${name}!` : "Welcome back!";

    return (
        <div className={styles["product-access-dashboard"]}>
            <Grid className={`${styles["product-access-dashboard__header"]}`}>
                <Typography type="header2">{welcomeMessage}</Typography>
                <Typography type="body">Choose a product to get started.</Typography>
            </Grid>
            <Grid className={styles["product-access-dashboard__product-grid"]}>
                <ProductGrid email={email} instancesByProducts={products} showIcons />
            </Grid>
        </div>
    );
};

ProductAccessDashboard.displayName = "ProductAccessDashboard";
