import { useQuery } from "../hooks/useQuery";
import { useHistory } from "react-router-dom";
import { LoadingIndicator } from "../components/LoadingIndicator/LoadingIndicator";
import { useEffect } from "react";

/**
 * Handles the redirect after the authentication.
 * @constructor
 */
export const Redirector = () => {
    const query = useQuery();
    const history = useHistory();
    const orgId = query.get('orgId');

    useEffect(() => {
        if (orgId) {
            history.push(`/${orgId.replace('/', '')}/dashboard`);
        }
    }, [history, orgId]);

    return (<LoadingIndicator />)
}