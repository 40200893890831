import { Button, Grid, GridCell, Spinner } from '@optimizely/axiom';
import React from 'react';

export const PendingInvitation = ({ loading, onAccept }: { loading?: boolean, onAccept: () => void }) => {
    return (
            <Grid className="flex flex-column align-items-center">
                <GridCell large={12} medium={8} small={4}>
                    <img src="/invitation-loading.png" alt="Loading" height="150" width="233" />
                </GridCell>
                <GridCell large={12} medium={8} small={4}>
                    {loading ? "Please wait while we process your request" : "Click the button below to accept your invitation to Optimizely"}
                </GridCell>
                <GridCell large={12} medium={8} small={4}>
                    {/* eslint-disable-next-line react/style-prop-object */}
                    <Button isDisabled={loading} style="highlight" onClick={onAccept}>
                        {loading ? <Spinner size="small" /> : 'Accept Invitation'}
                    </Button>
                </GridCell>

            </Grid>

    )
};

PendingInvitation.displayName = 'PendingInvitation';