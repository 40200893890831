import React from 'react';
import styles from './ProductCard.module.scss';
import { NavigationUrlTooltip } from "../NavigationUrlTooltip/NavigationUrlTooltip";
import { addQueryParam } from "../../utils/addQueryParam";
import { Link } from 'react-router-dom'
import { Card, LayoutGrid, LayoutGridCell, LayoutGridContainer, Link as ExternalLink, Typography } from "@optimizely/axiom";

type ProductCardProps = {
    email?: string;
    product: string,
    instances: any[]
};


const LIMIT = 5;
export const ProductCard = ({ email = "", product, instances }: ProductCardProps) => {
    // AC limit to first 5 and add link
    const hasMore = instances?.length > LIMIT;
    const toShow = instances.slice(0, LIMIT);

    return (
        <Card className={styles['product-card']} title={<Typography type="header4" tag="h4" title={product}>{product}</Typography>} shadow>
                <LayoutGridContainer className={styles["product-card__content"]}>
                    <LayoutGrid>
                    {toShow?.map(instance => {
                        const { NavigationUrl: url = "", InstanceNickname, InstanceName, InstanceId } = instance;
                        const displayText = InstanceNickname || InstanceName;
                        const href = url && email ? addQueryParam({ url, param: 'login_hint', value: email }) : url;
                        return (
                            <LayoutGridCell key={InstanceId} className="flex justify-content-between">
                                {!!(url && href)
                                    ? (
                                        <ExternalLink href={href}>
                                            <Typography type="body">{displayText}</Typography>
                                        </ExternalLink>)
                                    : (
                                        <>
                                            <Typography type="body">{displayText}</Typography>
                                            <NavigationUrlTooltip />
                                        </>
                                    )
                                }
                            </LayoutGridCell>
                        )
                    })}
                    {hasMore && <LayoutGridCell className={styles['product-card__footer']} large={12} medium={8} small={4}>
                        <Link to={`${product}/instances?page=1`} >See All Instances...</Link></LayoutGridCell>}
                    </LayoutGrid>
                </LayoutGridContainer>
        </Card>
    );
};

ProductCard.displayName = 'ProductCard';