import { Button, EmptyDashboard, Typography } from "@optimizely/axiom";
import { Link } from "react-router-dom";

import styles from "./LogoutDashboard.module.scss";

export const LogoutDashboard = () => {
    return (
            <EmptyDashboard
                className={styles["logout-dashboard"]}
                headline={""}
                description={
                    <Typography type="body">
                        You&apos;ve successfully logged out.
                    </Typography>
                }
                imagePath="/logout.png"
                imageHeight="auto"
                imageWidth="auto"
                button={
                    <Link to="/">
                        {/* eslint-disable-next-line react/style-prop-object */}
                        <Button style="highlight" isLink>
                            Log in to Optimizely
                        </Button>
                    </Link>
                }
                showButtonBelow
            />
    );
};

LogoutDashboard.displayName = "LogoutDashboard";
