import { Grid, GridCell, Typography } from '@optimizely/axiom';
import React from 'react';

export const InvitationInvalid = () => {

    return (
        <Grid className="flex flex-column align-items-center">
            <GridCell large={12} medium={8} small={4}>
                <img src="/invitation-expired.png" alt="Error" width={233} height={150} />
            </GridCell>

            <GridCell large={12} medium={8} small={4}>
                <Typography type="header4">
                    Invitation no longer valid
                </Typography>
            </GridCell>
            <GridCell className="push--top" large={12} medium={8} small={4}>
                <Typography type="body">
                    Please contact your administrator if you feel like this is an error
                </Typography>
            </GridCell>
        </Grid>
    );
};

InvitationInvalid.displayName = 'InvitationInvalid';