import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from "../hooks/useQuery";
import {  GridContainer } from '@optimizely/axiom';
import { PendingInvitation } from "../components/InvitationStates/PendingInvitation";
import { InvitationAccepted } from "../components/InvitationStates/InvitationAccepted";
import { InvitationInvalid } from "../components/InvitationStates/InvitationInvalid";
import { InvitationError } from "../components/InvitationStates/InvitationError";
export const InvitationPage = () => {
    const query = useQuery();
    const id = query.get('id');
    const [invitationState, setInvitationState] = useState<string | 'pending' | 'loading' | 'accepted' | 'invalid' | 'error'>('pending');
    const accepted = useRef(false);

    const attemptToAcceptInvitation = async () => {
        setInvitationState('loading');
        try {
            accepted.current = true;
            const res = await fetch(`${process.env.REACT_APP_IDX_SERVICE_URL}/api/invitations/${id}/accept`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                }
            });
            if ([400, 404].indexOf(res.status) > -1) {
                setInvitationState('invalid');
            } else if (res.status === 200) {
                setInvitationState('accepted');
            }
        } catch (err) {
            console.error(err);
            setInvitationState('error');
        }
    };
    useEffect(() => {
        // wait for query parameters to settle
        if (!id) {
            setInvitationState('error');
        }
    }, [id]);

    const loadingState = invitationState === 'loading';
    return (
        <GridContainer className="flex flex-column align-items-center">
            {(['loading', 'pending'].indexOf(invitationState) > -1) && (
                <PendingInvitation loading={loadingState} onAccept={attemptToAcceptInvitation} />
            )}
            {invitationState === 'accepted' && (<InvitationAccepted />)}
            {invitationState === 'invalid' && (<InvitationInvalid />)}
            {invitationState === 'error' && (<InvitationError />)}
        </GridContainer>

    );

}


InvitationPage.displayName = "InvitationPage";