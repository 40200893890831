import React from "react";
import { Grid, GridCell, GridContainer } from "@optimizely/axiom";
import { HelpAndResources } from "../HelpAndResources/HelpAndResources";

import styles from "./DashboardLayout.module.scss"

export const DashboardLayout = ({ children }: { children: React.ReactNode }) => {
    return (
        <GridContainer className={"noselect " + styles["dashboard-layout"]}>
            <Grid>
                <GridCell small={5} medium={5} large={9} xlarge={9}>
                    {children}
                </GridCell>
                <GridCell small={3} medium={3} large={3} xlarge={3} className={styles["dashboard-layout__help-column"]}>
                    <HelpAndResources />
                    <div id="marketing-space" />
                </GridCell>
            </Grid>
        </GridContainer>
    );
};

DashboardLayout.displayName = "DashboardLayout";