import { Grid, GridCell, Typography } from '@optimizely/axiom';
import React from 'react';

export const InvitationAccepted = () => {

    return (
        <Grid className="flex flex-column align-items-center">
            <GridCell large={12} medium={8} small={4}>
                <img src="/invitation-success.png" alt="Success" width={233} height={150} />
            </GridCell>
            <GridCell large={12} medium={8} small={4}>
                <Typography type="header4">Invitation Accepted</Typography>
            </GridCell>
            <GridCell large={12} medium={8} small={4}>
                <Typography type="body" className="push--top">
                    You can now log in to Optimizely
                </Typography>
            </GridCell>
            <GridCell large={12} medium={8} small={4}>
                <Typography type="body">Please check your email for login instructions</Typography>
            </GridCell>
        </Grid>
    )
};

InvitationAccepted.displayName = 'InvitationAccepted';