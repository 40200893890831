/* eslint-disable react/style-prop-object */
import styles from './HelpAndResources.module.scss';
import { Button, Typography } from "@optimizely/axiom";
import { ExternalWindow } from "../../icons";

const MENU_OPTIONS = [
    {
        href: 'https://episerver.zendesk.com/auth/v2/login/continue_with_sso_tracking?auth_origin=499034%2Ctrue%2Ctrue&brand_id=499034&locale=1&return_to=https%3A%2F%2F//support.optimizely.com/hc/requests/new%2F&role=end_user&theme=hc',
        text: 'Get Support',
    },
    { text: 'User Documentation', href: 'https://support.optimizely.com/hc/en-us' },
    { text: 'Developer Documentation', href: 'https://docs.developers.optimizely.com' },
    { text: 'Optimizely Academy', href: 'https://academy.optimizely.com' },
    { text: 'Slack Community', href: 'https://www.optimizely.com/slack-community/' },
    { text: 'Optimizely Partners', href: 'https://www.optimizely.com/partners/' },
    { text: 'Give Product Feedback', href: 'https://feedback.optimizely.com/ideas/' },
    { text: 'Legal Notices', href: 'https://www.optimizely.com/legal/terms/' },
];
export const HelpAndResources = () => {

    const openLink = ({ href }: { href: string }) => {
        window.open(href, "_blank");
    }

    return (
        <div className={styles['help-and-resources']}>
            <Typography type="header4" className={"noselect " + styles['help-and-resources__heading']}>Help & Resources</Typography>
            <div className={styles['help-and-resources__container']}>
                {MENU_OPTIONS.map((option, optionIndex) => {
                    return (
                        <Button style="plain" size="narrow" className={styles['help-and-resources__link']} onClick={() => openLink({ href: option.href })} key={optionIndex}>
                            <ExternalWindow />
                            <span>{option.text}</span>
                        </Button>
                    );
                })}
            </div>
        </div>
    )
}