import { useOktaAuth } from "@okta/okta-react";
import { LoadingIndicator } from "../components/LoadingIndicator/LoadingIndicator";

import { DashboardLayout } from "../components/DashboardLayout/DashboardLayout";
import { LogoutDashboard } from "../components/LogoutDashboard/LogoutDashboard";
 

export const Logout = () => {
    const { authState, oktaAuth } = useOktaAuth();

    if (authState?.isAuthenticated) {
        oktaAuth.signOut().then(() => {
            oktaAuth.tokenManager.clear();
        });
        return (<LoadingIndicator />);
    }

    return (<DashboardLayout><LogoutDashboard /></DashboardLayout>);
};

Logout.displayName = "LogoutPage";
