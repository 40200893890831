import React from "react";
import { useQuery } from "../../hooks/useQuery";
import { InstancePermissionItem } from "../../hooks/useInstancePermissions";
import { Grid, GridCell, GridContainer, PaginationControls, Table, Typography } from "@optimizely/axiom";
import { addQueryParam } from "../../utils/addQueryParam";
import { useHistory, useLocation } from "react-router-dom";
import { NavigationUrlTooltip } from "../NavigationUrlTooltip/NavigationUrlTooltip";

const PAGE_SIZE = 20;


export const ProductInstancesTable = ({ email, instances, product }: { email: string, instances: InstancePermissionItem[], product: string }) => {
    const history = useHistory()
    const { pathname, search } = useLocation();
    const query = useQuery();
    const queryPage = query.get('page');
    const page = queryPage ? +queryPage : 1;

    const handleChangePage = (page: number = 1) => {
        const searchParams = new URLSearchParams(search);
        searchParams.set('page', `${page}`);

        history.push({
            pathname,
            search: searchParams.toString()
        });
    };

    if (!instances?.length) {
        return (<div>No instances</div>)
        // return (
        //     <Grid container direction="column" alignContent="center" m={4}>
        //         <Box my={4}>You have not been granted access to instances in the organization you navigated to.</Box>
        //         <Button type="contained" size="small" href="/" >Back to Dashboard</Button>
        //     </Grid>
        // )
    }

    const totalPages = Math.ceil(instances?.length / PAGE_SIZE);


    return (
        <GridContainer>
            <Grid>
                <GridCell>
                    <Typography type="header4">{product} Instances</Typography>
                </GridCell>
                <GridCell>
                    <Table density="loose">
                        <Table.THead>
                            <Table.TR>
                                <Table.TH>Instance Name</Table.TH>
                                <Table.TH>Region</Table.TH>
                            </Table.TR>
                        </Table.THead>
                        <Table.TBody>
                            {(instances?.length > PAGE_SIZE
                                    ? instances?.slice((page - 1) * PAGE_SIZE, (page - 1) * PAGE_SIZE + PAGE_SIZE)
                                    : instances
                            )?.map((instance: any) => {
                                    const url = instance.NavigationUrl;
                                    const href = url ? addQueryParam({ url, param: 'login_hint', value: email }) : null;

                                    return (
                                        <Table.TR key={instance.InstanceId}>
                                            <Table.TD className="flex">
                                                {!!href ? (
                                                        <a
                                                            href={href}
                                                            style={{ color: 'var(--axiom-link-default-text-color)' }}
                                                        >
                                                            {instance.InstanceNickname || instance.InstanceName}
                                                        </a>
                                                    ) :
                                                    (<>
                                                        <span className="push--right">{instance.InstanceNickname || instance.InstanceName}</span>
                                                        <NavigationUrlTooltip />
                                                    </>)
                                                }
                                            </Table.TD>
                                            <Table.TD>
                                                {instance.Region}
                                            </Table.TD>
                                        </Table.TR>
                                    );
                                }
                            )}
                        </Table.TBody>
                    </Table>
                </GridCell>
                <GridCell>
                    {totalPages > 1 && (
                        <PaginationControls
                            totalPages={totalPages}
                            currentPage={(+page)}
                            goToPage={handleChangePage}
                        />
                    )}
                </GridCell>
            </Grid>

        </GridContainer>
    );


}

ProductInstancesTable.displayName = "ProductInstancesTable";